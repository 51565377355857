<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn
          icon
          @click="$router.push({ name: 'ai/smart-store/list' })"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        스마트스토어 리뷰
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="scraping"
          @click="scrapeReviews"
        >
          <v-icon left>mdi-refresh</v-icon>
          리뷰 가져오기
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="검색"
                single-line
                hide-details
                @input="onSearch"
                @keyup.enter="onSearch"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              color="primary"
              :loading="item.loading"
              @click="analyzeReview(item)"
            >
              GPT 분석
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- GPT 응답 다이얼로그 -->
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>GPT 분석 결과</v-card-title>
        <v-card-text>
          <div v-if="gptLoading" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            {{ gptResponse }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import smartStoreApi from '@/plugins/api/now/smart-store'

export default {
  name: 'SmartStoreReviews',
  data() {
    return {
      search: '',
      loading: false,
      scraping: false,
      options: {},
      totalItems: 0,
      headers: [
        { text: '리뷰 ID', value: 'id' },
        { text: '닉네임', value: 'nickname' },
        { text: '내용', value: 'content' },
        { text: '작성일', value: 'date' },
        { text: '분석', value: 'actions', sortable: false },
      ],
      items: [],
      dialog: false,
      gptLoading: false,
      gptResponse: '',
    }
  },
  methods: {
    async fetchItems() {
      this.loading = true
      try {
        const response = await smartStoreApi.getReviews(this.$route.params.id)
        let filteredItems = response.data || []

        // 검색어가 있는 경우 필터링
        if (this.search) {
          const searchLower = this.search.toLowerCase()
          filteredItems = filteredItems.filter(item =>
            item.content.toLowerCase().includes(searchLower)
          )
        }

        // 각 아이템에 loading 상태 추가
        this.items = filteredItems.map(item => ({
          ...item,
          loading: false
        }))
        this.totalItems = filteredItems.length
      } catch (error) {
        console.error('리뷰 목록 조회 실패:', error)
        this.items = []
        this.totalItems = 0
      } finally {
        this.loading = false
      }
    },
    onSearch() {
      this.options.page = 1
      this.fetchItems()
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    },
    async generateGptMessage(content) {
      const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions'
      // API 키는 환경변수로 이동해야 함
      const OPENAI_API_KEY = process.env.VUE_APP_OPENAI_API_KEY || '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'

      try {
        console.log('OpenAI API 요청 시작:', {
          url: OPENAI_API_URL,
          content,
        })

        const response = await fetch(OPENAI_API_URL, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'system',
                content: `당신은 리뷰를 분석하고 마케팅 메시지를 제안하는 전문가입니다. 진짜 고객이 쓴 리뷰에서 이 상점의 자랑거리를 파악해서 다른 고객들의 재방문을 유도할 짧은 SMS 메시지를 만들어주세요. 고객의 방문 욕구를 불러일으키는 제안이 중요합니다.
                
                [효과적인 푸시 알림 전략]
명확한 가치 제안: 알림을 통해 고객이 얻을 수 있는 이점을 분명히 설명하세요.
시기 적절성: 고객의 행동이나 관심사에 기반하여 적절한 시점에 알림을 보내세요.
개인화: 고객의 이름, 과거 구매 내역, 관심사 등을 활용하여 메시지를 개인화하세요.
간결성: 메시지는 짧고 명확하게 작성하여 핵심을 전달하세요.
행동 유도: 명확한 행동 지침(CTA)을 포함하여 고객의 반응을 유도하세요.

** 하지만 할인 등 비용이 수반되는 제안은 하지 말아 주세요.`,
              },
              {
                role: 'user',
                content,
              },
            ],
            temperature: 0.7,
            max_tokens: 1000,
          }),
        })

        console.log('OpenAI API 응답 상태:', {
          status: response.status,
          statusText: response.statusText,
        })

        if (!response.ok) {
          const errorData = await response.json()
          console.error('OpenAI API 에러 응답:', errorData)
          throw new Error(errorData.error?.message || '분석 중 오류가 발생했습니다.')
        }

        const result = await response.json()
        console.log('OpenAI API 응답 데이터:', {
          choices: result.choices,
          usage: result.usage,
        })

        return result.choices[0].message.content
      } catch (error) {
        console.error('OpenAI API 호출 중 예외 발생:', error)
        throw error
      }
    },
    async analyzeReview(item) {
      item.loading = true
      this.dialog = true
      this.gptLoading = true
      this.gptResponse = ''

      try {
        const prompt = `다음 리뷰를 기반으로 짧은 SMS 광고를 만들어주세요: "${item.content}"`
        this.gptResponse = await this.generateGptMessage(prompt)
      } catch (error) {
        console.error('GPT 분석 실패:', error)
        this.gptResponse = '분석 중 오류가 발생했습니다.'
      } finally {
        item.loading = false
        this.gptLoading = false
      }
    },
    async scrapeReviews() {
      this.scraping = true
      try {
        await smartStoreApi.scrapeReviews(this.$route.params.id)
        // 스크래핑 완료 후 리뷰 목록 새로고침
        await this.fetchItems()
        // this.$toast.success('리뷰를 성공적으로 가져왔습니다.')
      } catch (error) {
        console.error('리뷰 스크래핑 실패:', error)
        // this.$toast.error('리뷰 가져오기에 실패했습니다.')
      } finally {
        this.scraping = false
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  created() {
    this.fetchItems()
  },
}
</script>

<style scoped>
.v-card {
  margin: 16px;
}
</style>
